<template>
  <keep-alive :include="aliveRouter">
    <router-view class="content-layout"></router-view>
  </keep-alive>
</template>

<script>
export default {
  name: 'Info',
  mounted() {},
  data() {
    return {
      aliveRouter: [
        'ServiceProviderList',
        'BrandList',
        'DeviceTypeList',
        'RemoteControllerList',
        'RegionManagement',
        'ProductManagement',
        'ProductCategoryManagement',
        'ProductSubClassManagement',
        'LoadedDeviceManagement',
        'PropertyManagement',
        'ProductServiceManagement',
        'ProductConfigurationManagement',
        'LanguageManagement',
        'EntryManagement',
        'I18nEntityManagement',
        'HolidaysManagement',
        'ProductModelManagement',
        'ProductModelCreator',
        'ProductModelEditor',
        'ProductCreator',
        'ProductEditor',
        'ScenceTypeManagement',
      ],
    };
  },
};
</script>

<style scoped>
.content-layout {
  padding: 10px;
}
</style>
